import axios from 'axios'

const http = axios.create({
  baseURL: 'https://srv-pbx-02.chaika.com/api'
})

export const queues = [
  { queue: '292', title: 'Очередь ДД' },
  { queue: '282', title: 'Очередь стоматологии МП' },
  { queue: '296', title: 'Очередь травматологии КН' },
  { queue: '271', title: 'Очередь практики ЛОР КН' },
  { queue: '272', title: 'Очередь практики неврологии КН' },
  { queue: '273', title: 'Очередь практики гинекологии КН' },
  { queue: '274', title: 'Очередь практики терапии КН' },
  { queue: '275', title: 'Очередь практики педиатрии КН' },
  { queue: '276', title: 'Очередь практики стоматологии КН' },
  { queue: '277', title: 'Очередь практики эндокринологии КН' },
];

export default {
  reg (shortNumber, queue) {
    return http.post("/queue-by-code", { shortNumber, queue });
  },

  unreg (shortNumber, queue) {
    return http.delete("/queue-by-code", { data: { shortNumber, queue }})
  }
}
