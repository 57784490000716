var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-header',{staticClass:"mb-2",attrs:{"sm12":"","title":"Очереди телефонии"}}),_c('v-flex',[_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"q-dd"},[_c('div',{staticClass:"queue-field"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"q-dd__form"},[_c('validation-provider',{attrs:{"rules":"required","name":"Номер"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Номер","placeholder":"Введите номер","error-messages":errors,"required":"","outlined":"","autofocus":"","dense":""},model:{value:(_vm.form.shortNumber),callback:function ($$v) {_vm.$set(_vm.form, "shortNumber", $$v)},expression:"form.shortNumber"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"Очередь"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Очередь","placeholder":"Очередь","items":_vm.queues,"item-text":"title","item-value":"queue","error-messages":errors,"required":"","outlined":"","dense":""},model:{value:(_vm.form.queue),callback:function ($$v) {_vm.$set(_vm.form, "queue", $$v)},expression:"form.queue"}})]}}],null,true)})],1),_c('div',{staticClass:"queue-buttons"},[_c('v-btn',{attrs:{"color":"success","disabled":_vm.inProcess || invalid},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.reg)}}},[_vm._v(" Встать в очередь ")]),_c('v-btn',{attrs:{"disabled":_vm.inProcess || invalid},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.unreg)}}},[_vm._v(" Выйти ")])],1)]}}])})],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }