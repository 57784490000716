<template>
  <div>
    <page-header
      class="mb-2" sm12 title="Очереди телефонии">
    </page-header>
    <v-flex>
      <v-card>
        <v-card-text>
          <div class="q-dd">
            <div class="queue-field">
              <ValidationObserver v-slot="{ invalid, handleSubmit }">
                <div class="q-dd__form">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="Номер"
                  >
                    <v-text-field
                      v-model="form.shortNumber"
                      label="Номер"
                      placeholder="Введите номер"
                      :error-messages="errors"
                      required
                      outlined
                      autofocus
                      dense
                    ></v-text-field>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="Очередь"
                  >
                    <v-select
                      label="Очередь"
                      v-model="form.queue"
                      placeholder="Очередь"
                      :items="queues"
                      item-text="title"
                      item-value="queue"
                      :error-messages="errors"
                      required
                      outlined
                      dense
                    />
                  </validation-provider>
                </div>
                <div class="queue-buttons">
                  <v-btn @click.prevent="handleSubmit(reg)" color="success" :disabled="inProcess || invalid">
                    Встать в очередь
                  </v-btn>
                  <v-btn @click.prevent="handleSubmit(unreg)" :disabled="inProcess || invalid">
                    Выйти
                  </v-btn>
                </div>
              </ValidationObserver>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
  import asteriskService, { queues } from '../../services/asterisk'
  import { Notification } from 'element-ui'
  import PageHeader from "@/components/PageHeader.vue";

  export default {
    components: {
      PageHeader,
    },
    computed: {
      queues: function () {
        return queues.map((i) => {
          i.title = i.queue + ' - ' + i.title
          return i
        });
      }
    },
    data () {
      return {
        form: { shortNumber: null, queue: null },
        inProcess: false
      }
    },
    methods: {
      async reg () {
        if (this.inProcess) {
          return
        }
        this.inProcess = true
        try {
          await asteriskService.reg(this.form.shortNumber, this.form.queue)
          Notification({
            title: 'Готово!',
            message: 'Добавлено в очередь',
            type: 'success',
            duration: 3000
          })
        } catch (e) {
          this.onError(e)
        } finally {
          this.inProcess = false
        }
      },
      async unreg () {
        if (this.inProcess) {
          return
        }
        if (!confirm('Исключить из очереди?')) return
        this.inProcess = true
        try {
          await asteriskService.unreg(this.form.shortNumber, this.form.queue)
          Notification({
            title: 'Готово!',
            message: 'Исключено из очереди',
            type: 'success',
            duration: 3000
          })
        } catch (e) {
          this.onError(e)
        } finally {
          this.inProcess = false
        }
      },
      onError (e) {
        const message = e.response?.data?.message || e.message
        Notification({
          title: 'Ошибка',
          message: message,
          type: 'error',
          duration: 3000
        })
      }
    }
  }
</script>

<style lang="scss">
  .queue-buttons {
    display: inline-flex;
    align-items: center;

    & > * {
      margin-right: 32px;
    }
  }

  .q-dd__form {
    @media screen and (min-width: 768px) {
      display: flex;
      grid-gap: 20px;
      & > * {
        flex: 0 0 auto;
      }
    }
  }
</style>
